//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    Header: () => import("@/components/Header"),
    CPages: () => import("@/components/CPages"),
    AuditModal: () => import("./AuditModal"),
  },
  props: {
    isApply: {
      type: Boolean,
      default: false,
    },
    teamId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      auditInfo: null,
      teamOptions: [], //团队选择器
      teamValue: null, //选择的团队
      auditData: [],
      tableLoading: false,
      pageData: {
        pageIndex: 1,
        pageSize: 10,
        totalNum: 0,
      },
      applyStatus: 1, //审核状态

      auditOperateOptions: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 1,
          label: "待审核",
        },
        {
          value: 2,
          label: "通过",
        },
        {
          value: 3,
          label: "驳回",
        },
      ],
      auditOperateOptions2: [
        {
          value: 1,
          label: "待审批",
        },
        {
          value: 2,
          label: "已审批",
        },
      ],
    };
  },
  filters: {
    auditResultFilters(value) {
      switch (value) {
        case 1:
          return "待审核";
        case 2:
          return "通过";
        case 3:
          return "驳回";
        default:
          break;
      }
    },
  },
  mounted() {
    this.getData();
    if (!this.isApply) {
      this.getTeams();
    }
  },
  methods: {
    /**
     * 团队切换
     */
    initData() {
      this.pageData.pageIndex = 1;
      this.getData();
    },
    /**
     * 获取团队
     */
    getTeams() {
      this.$http
        .get("/Teams/GetAllTeams.ashx", {
          params: { searchText: null, type: 2 },
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.teamOptions = resp.data;
          }
        });
    },
    //取消申请
    auditCancel(index, audit) {
      this.$confirm("确认取消申请?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let resp = await this.$http.post("/MGT/Process/AuditBack.ashx", {
          auditID: audit.auditID,
        });
        if (resp.res == 0) {
          this.$notify({
            type: "success",
            message: "取消成功",
          });
          this.initData();
        }
      });
    },
    handleAudit(row) {
      this.auditInfo = row;
      this.$modal.show("applyAudit");
    },
    /**
     * 获取数据
     */
    getData() {
      this.tableLoading = true;
      const data = {
        teamId: this.isApply ? this.teamId : this.teamValue,
        type: this.isApply ? 2 : 1,
        aStatus: this.applyStatus,
        pageIndex: this.pageData.pageIndex,
        pageSize: this.pageData.pageSize,
      };
      this.$http
        .post("/Attendance/AnomalyAppeals/GetAnomalyAppeals.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.auditData = resp.data.Data;
            this.pageData.totalNum = resp.data.TotalCount;
          }
        })
        .finally(() => (this.tableLoading = false));
    },
    /**
     * 分页
     */
    handlePaginationChange(val) {
      this.pageData = val;
      this.getData();
    },
  },
};
